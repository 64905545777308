import { useCollection, useFirestore } from "vuefire"
import { FirebaseAuthentication } from "@capacitor-firebase/authentication"
import {
  signInWithCredential,
  GoogleAuthProvider,
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth"
import {
  collection,
  query,
  where,
  doc,
  addDoc,
  updateDoc,
  getDoc,
  deleteDoc,
  orderBy,
  getDocs,
  or,
} from "firebase/firestore"
import { getStorage, ref, uploadString, getDownloadURL } from "firebase/storage"
import axios from "axios"

const config = useRuntimeConfig()

export const useStore = () => {
  let language = "it"

  return {
    async addStage(data: any) {
      const user = await useStore().loadUser()
      if(data?.info_stage?.img) {
        const search = new URLSearchParams()
        search.append("url", data.info_stage.img)

        const image = await fetch(
          `${config.public.backendApi}/api/image?${search.toString()}&type=base64`
        )

        if (image.ok) {
          const strImageBase64 = (await image.json()).str
          const storage = getStorage()
          const storageRef = ref(storage, `${data.info_stage.name}_${new Date().getTime()}`)
          const metadata = {
            contentType: "image/jpeg",
          }
          await uploadString(
            storageRef,
            strImageBase64,
            "base64",
            metadata
          )
          
          const urlImgWithQuery = await getDownloadURL(storageRef)
          const urlSplitted = urlImgWithQuery.split("?")
          const urlImg = `${urlSplitted[0]}?alt=media`
          data.info_stage.img = urlImg          
        }        
      }
      data.createdAt = new Date()
      data.id_user = user?.data.id

      const all = await this.loadStages(data.itinerary_id)
      data.position = all.length
      
      await addDoc(collection(useFirestore(), "stages"), data)
      clearNuxtState("place")
    },
    switchLanguage(lang: string) {
      language = lang
    },
    async loadUser() {
      try {
        const user = await getCurrentUser()
        if (user) {
          const usersRef = collection(useFirestore(), "users")
          const q = query(usersRef, where("id", "==", user.uid))
          const data = await getDocs(q)
          if (!data.empty) {
            return { data: data.docs[0].data(), idUser: data.docs[0].id }
          }
        }
        return null
      } catch (error) {
        console.log(error)
        return null
      }
    },
    async addUser(data: any) {
      await addDoc(collection(useFirestore(), "users"), data)
    },
    async updateUser(data: any) {
      await updateDoc(doc(useFirestore(), "users", data.idUser), data.data)
    },
    async googleLogin() {
      // 1. Create credentials on the native layer
      const result = await FirebaseAuthentication.signInWithGoogle()
      // 2. Sign in on the web layer using the id token
      const credential = GoogleAuthProvider.credential(
        result.credential?.idToken
      )
      const auth = getAuth()
      const data = await signInWithCredential(auth, credential)
      const user = await useStore().loadUser()
      useState("user", () => data.user)
      if (user) {
        if (!user.data.interests) {
          //mancano gli interessi
          useRouter().push("/interests")
        } else {
          //utente con tutte le info e già registrato
          useRouter().push("/")
        }
      } else {
        // new user!
        useRouter().push("/finish-register?provider=google")
      }
    },
    async registerWithEmailPassword(data: any) {
      const auth = getAuth()
      try {
        const result = await createUserWithEmailAndPassword(
          auth,
          data.email,
          data.password
        )
        await useStore().addUser({
          id: result.user.uid,
          email: data.email,
          name: data.name,
          birthday: data.date,
          surname: data.surname,
        })
        await sendEmailVerification(auth.currentUser)
      } catch (error) {
        return error
      }
      return {
        success: true
      }
    },
    async loginWithEmailPassword(data: any) {
      const auth = getAuth()
      try {
        const result = await signInWithEmailAndPassword(
          auth,
          data.email,
          data.password
        )
        if (!result.user.emailVerified) {
          return { success: false, error: 'custom/not-verified' }
        }
        console.log(result)
        useState("user", () => result.user)
        const user = await useStore().loadUser()
        if (!user) {
          useRouter().push("/finish-register")
          return
        }
        if (!user.data.interests) {
          useRouter().push("/interests")
        } else {
          useRouter().push("/")
        }
      } catch (error: any) {
        return { success: false, error: error.code }
      }
    },
    async loadInterests(id: string) {
      const q = query(
        collection(useFirestore(), "users"),
        where("id", "==", id)
      )
      const data = await getDocs(q)
      let res = []
      data.docs.forEach((doc) => {
        doc?.data()?.interests?.forEach((interest) => {
          res.push(interest)
        })
      })
      return res
    },
    async saveInterests(id: string, interests: Array<string>) {
      const userRef = doc(useFirestore(), `users/${id}`)
      await updateDoc(userRef, {
        interests: interests,
      })
    },
    async sendEmailToResetPassword(email: string) {
      const auth = getAuth()
      await sendPasswordResetEmail(auth, email)
    },
    async addItinerary(data: any) {
      const search = new URLSearchParams()
      search.append("url", data.img)

      const image = await fetch(
        `${config.public.backendApi}/api/image?${search.toString()}&type=base64`
      )
      if (image.ok) {
        const strImageBase64 = (await image.json()).str
        const storage = getStorage()
        const storageRef = ref(storage, `${data.name}_${new Date().getTime()}`)
        const metadata = {
          contentType: "image/jpeg",
        }
        await uploadString(
          storageRef,
          strImageBase64,
          "base64",
          metadata
        )
        const urlImgWithQuery = await getDownloadURL(storageRef)
        const urlSplitted = urlImgWithQuery.split("?")
        const urlImg = `${urlSplitted[0]}?alt=media`
        data.img = urlImg

        const user = await useStore().loadUser()
        data["id_user"] = user?.data.id
        const res = await addDoc(
          collection(useFirestore(), "itineraries"),
          data
        )
        return res.id
      }
      return null
    },
    async loadItinerary(id: string) {
      const docRef = doc(useFirestore(), "itineraries", id)
      const docInfo = await getDoc(docRef)
      return docInfo.data()
    },
    async loadItineraries() {
      const user = await getCurrentUser()
      const q = query(
        collection(useFirestore(), "itineraries"),
        or(where("id_user", "==", user?.uid),
        where("co_owners", "array-contains", user.email.toLowerCase())
      ));     
      
      const data = await getDocs(q)
      let res = []
      data.docs.forEach((doc) => {
        res.push({ data: doc.data(), id: doc.id })
      })
      return res
    },
    async loadStages(id: string) {
      const q = query(
        collection(useFirestore(), "stages"),
        where("itinerary_id", "==", id),
        orderBy('position'),
        orderBy('createdAt'),        
      )
      const data = await getDocs(q)
      console.log(data)
      let res = []
      data.docs.forEach((doc) => {
        res.push({ data: doc.data(), id: doc.id })
      })
      return res
    },
    async saveOrder(stages) {
      await Promise.all(stages.map(stage => {
        this.editStage(stage.id, stage)
      }))
    },
    async deleteItinerary(id: string) {
      const stages = await this.loadStages(id)
      for (let i = 0; i < stages.length; i++) {
        await deleteDoc(doc(useFirestore(), "stages", stages[i].id))
      }
      await deleteDoc(doc(useFirestore(), "itineraries", id))
    },
    async editItinerary(id: string, data: any) {
      const itineraryRef = doc(useFirestore(), `itineraries/${id}`)
      await updateDoc(itineraryRef, data)
    },
    async loadStage(id: string) {
      const docRef = doc(useFirestore(), "stages", id)
      const docInfo = await getDoc(docRef)
      return docInfo.data()
    },
    async deleteStage(id: string) {
      await deleteDoc(doc(useFirestore(), "stages", id))
    },
    async addPlaceInfo(data: any, stage: any) {
      await addDoc(collection(useFirestore(), "informations"), data)

      await useFetch(`${config.public.backendApi}/api/notify-info`, {
        method: 'post',
        body: {
          id: data.place_id,
          name: data.path[0],
          value: data.option,
          placeName: stage.info_stage.name,
          image: stage.info_stage.img
        }
      })
    },
    async loadInfos(id: string) {
      const q = query(
        collection(useFirestore(), "informations"),
        where("place_id", "==", id)
      )
      const data = await getDocs(q)
      let res = []
      data.docs.forEach((doc) => {
        res.push({ data: doc.data(), id: doc.id })
      })
      return res
    },
    async addReport(data: any) {
      const user = await useStore().loadUser()
      data["id_user"] = user?.data.id

      await addDoc(collection(useFirestore(), "user_reports"), data)      
      await useFetch(`${config.public.backendApi}/api/notify-wrong-info`, {
        method: 'post',
        body: data
      })
    },
    async editStage(id: string, data: any) {
      const stageRef = doc(useFirestore(), `stages/${id}`)
      await updateDoc(stageRef, data)
    },
    async loadJammyTripItineraries() {
      const response = await axios.get(`${config.public.jammyTripsUrl}`)
      if(response.status == 200) {
        return response.data
      } else {
        return null
      }
    },
    async loadJammyTripItinerarySelected(id: number) {
      const response = await axios.get(`${config.public.backendApi}/api/trips/${id}`)
      if(response.status == 200) {
        return response.data
      } else {
        return null
      }
    },
    async loadShopProducts() {
      const response = await axios.get(`${config.public.backendApi}/api/shop-items`)
      if(response.status == 200) {
        return response.data
      } else {
        return null
      }
    },
    async logout() {
      await FirebaseAuthentication.signOut()
    }
  }
}
